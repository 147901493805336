var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"regular-forms-view","fluid":"","tag":"section"}},[_c('v-row',[(!_vm.isNew && !_vm.eservice)?_c('v-col',{attrs:{"cols":"12"}},[_c('i',{staticClass:"fas fa-spin fa-spinner"})]):_c('v-col',{attrs:{"cols":"12"}},[_c('material-card',{attrs:{"color":"primary","heading":_vm.pageTitle}},[_c('v-card-text',[(!_vm.isNew && !_vm.eservice)?_c('i',{staticClass:"fas fa-spin fa-spinner"}):_vm._e(),_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('forms.label').toLowerCase()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"outlined":"","label":_vm.$t('forms.label')},model:{value:(_vm.form.label),callback:function ($$v) {_vm.$set(_vm.form, "label", $$v)},expression:"form.label"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('forms.link').toLowerCase()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"outlined":"","label":_vm.$t('forms.link')},model:{value:(_vm.form.link),callback:function ($$v) {_vm.$set(_vm.form, "link", $$v)},expression:"form.link"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('forms.category').toLowerCase()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.categories,"item-text":"label","item-value":"id","outlined":"","error-messages":errors,"label":_vm.$t('forms.category')},model:{value:(_vm.form.eservice_category_id),callback:function ($$v) {_vm.$set(_vm.form, "eservice_category_id", $$v)},expression:"form.eservice_category_id"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"","name":_vm.$t('forms.enabled').toLowerCase()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-switch',{attrs:{"label":_vm.$t('forms.enabled'),"error-messages":errors},model:{value:(_vm.form.is_enabled),callback:function ($$v) {_vm.$set(_vm.form, "is_enabled", $$v)},expression:"form.is_enabled"}})]}}],null,true)}),_c('div',{staticClass:"full-width d-flex justify-space-between align-center"},[_c('v-btn',{attrs:{"color":"gray","to":"/eservices/eservices"}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),_c('v-btn',{attrs:{"type":"submit","color":_vm.processing ? 'gray' : 'success'}},[_c('v-icon',{staticClass:"mr-3",attrs:{"small":""},domProps:{"textContent":_vm._s(_vm.processing ? 'fas fa-spinner fa-spin' : 'fas fa-check')}}),_vm._v(" "+_vm._s(_vm.$t('submit'))+" ")],1)],1)],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }