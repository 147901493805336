<template>
  <v-container
    id="regular-forms-view"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        v-if="!isNew && !eservice"
        cols="12"
      >
        <i class="fas fa-spin fa-spinner"></i>
      </v-col>
      <v-col
        v-else
        cols="12"
      >
        <material-card
          color="primary"
          :heading="pageTitle"
        >
          <v-card-text>
            <i
              v-if="!isNew && !eservice"
              class="fas fa-spin fa-spinner"
            ></i>
            <validation-observer v-slot="{ handleSubmit }">
              <v-form @submit.prevent="handleSubmit(submit)">
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  :name="$t('forms.label').toLowerCase()"
                >
                  <v-text-field
                    v-model="form.label"
                    :error-messages="errors"
                    outlined
                    :label="$t('forms.label')"
                  />
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  :name="$t('forms.link').toLowerCase()"
                >
                  <v-text-field
                    v-model="form.link"
                    :error-messages="errors"
                    outlined
                    :label="$t('forms.link')"
                  />
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  :name="$t('forms.category').toLowerCase()"
                >
                  <v-select
                    v-model="form.eservice_category_id"
                    :items="categories"
                    item-text="label"
                    item-value="id"
                    outlined
                    :error-messages="errors"
                    :label="$t('forms.category')"
                  />
                </validation-provider>
                <validation-provider
                  v-slot="{ errors }"
                  rules=""
                  :name="$t('forms.enabled').toLowerCase()"
                >
                  <v-switch
                    v-model="form.is_enabled"
                    :label="$t('forms.enabled')"
                    :error-messages="errors"
                  />
                </validation-provider>
                <div class="full-width d-flex justify-space-between align-center">
                  <v-btn
                    color="gray"
                    to="/eservices/eservices"
                  >
                    {{ $t('cancel') }}
                  </v-btn>
                  <v-btn
                    type="submit"
                    :color="processing ? 'gray' : 'success'"
                  >
                    <v-icon
                      class="mr-3"
                      small
                      v-text="processing ? 'fas fa-spinner fa-spin' : 'fas fa-check'"
                    />
                    {{ $t('submit') }}
                  </v-btn>
                </div>
              </v-form>
            </validation-observer>
          </v-card-text>
        </material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex';
  import serverError from '../../mixins/serverError';
  export default {
    name: 'EserviceEserviceForm',

    mixins: [serverError],

    data: () => ({
      form: {
        label: null,
        link: null,
        eservice_category_id: null,
        is_enabled: true,
      },
    }),

    computed: {
      ...mapGetters({
        processing: 'global/getProcessing',
        eservice: 'eservice/getEservice',
        categories: 'eservice/getCategories',
      }),
      isNew() {
        return !this.$route.params.id;
      },
      pageTitle() {
        return this.isNew ? this.$t('pageTitles.NewService') : this.$t('pageTitles.Editing') + this.eservice.label;
      },
    },

    created() {
      this.$store.dispatch('eservice/fetchCategories');
      if (!this.isNew) {
        this.$store.dispatch('eservice/fetchEservice', this.$route.params.id)
          .then(() => {
            this.form = { ...this.eservice };
          });
      }
    },

    methods: {
      submit () {
        if (!this.processing) {
          const { id, label, eservice_category_id, link, is_enabled } = this.form
          const payload = { id, label, eservice_category_id, link, is_enabled };
          const action = this.isNew ? 'eservice/createEservice' : 'eservice/updateEservice';
          const message = this.isNew ? this.$t('successfully_created') : this.$t('successfully_updated');
          this.$store.dispatch(action, payload)
            .then(() => {
              this.$toasted.success(message);
              this.$router.push('/eservices/eservices');
            })
            .catch(error => this.displayErrors(error));
        }
      },
    },
  }
</script>
